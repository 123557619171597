// back-to-somewhere applied universally
// placeholder text for subscribe is weird position in safari
// Add site map
// Fix MailChimp
// Fix Contact to setup with her netlify

// INDEX PAGE
// how to make sure next three events keep showing.
// make sure you can tab to the next field of subscribe box (rebuild subscribe component)
// make sure preview only shows one events
// Upcoming Performance needs to work better when it goes on to two lines


// Event Page
// when event is past make it disappear.
// box-shadow hover go away for mobile

// PERFORMANCES PAGE

// LISTEN PAGE
// https://convertio.co/ to shrink files to 6.4MB mp4
// descriptions look like crap

// CONTACT PAGE
// add a photo, maybe like an avatar
// what shoudl the text say?

// BIO PAGE

// THANKYOU PAGE

// Look into SEO details

import React from "react"
import { graphql } from "gatsby"
import {
  BiographyShort,
  Intro,
  Layout,
  PerformancePreview,
  SEO,
  Subscribe,
} from "../components"

const Home = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title
  const picture = data.IntroPicture.childImageSharp.fluid
  return (
    <Layout title={siteTitle}>
      <SEO title="Home" />
      <Intro bg="bg-primary" svgFill="primary" picture={picture} />
      <PerformancePreview />
      <BiographyShort />
      <Subscribe />
    </Layout>
  )
}

export default Home

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    IntroPicture: file(absolutePath: { regex: "/allie-headshot-2-square.jpg/" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
